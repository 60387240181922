import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,2,3,4,5];

export const dictionary = {
		"/": [6],
		"/about": [~8],
		"/account": [9],
		"/account/update-password": [~10],
		"/auth/verify": [~11],
		"/contact": [~12],
		"/dashboard": [~13,[2]],
		"/dashboard/account": [~14,[2,3]],
		"/dashboard/account/[account_id]": [~15,[2,3,4]],
		"/dashboard/account/[account_id]/withdraw": [~16,[2,3,4]],
		"/dashboard/application/new": [~17,[2,5]],
		"/dashboard/application/review/[application_id]": [~18,[2,5]],
		"/dashboard/application/review/[application_id]/individual/[corp_individual_id]": [~19,[2,5]],
		"/dashboard/fees": [~20,[2]],
		"/dashboard/invoices": [~21,[2]],
		"/dashboard/invoices/new": [~23,[2]],
		"/dashboard/invoices/[id]": [~22,[2]],
		"/dashboard/knowledge-center": [~24,[2]],
		"/dashboard/knowledge-center/[slug]": [25,[2]],
		"/dashboard/referrals": [~26,[2]],
		"/e-sign-disclosure-and-consent": [~27],
		"/invoice/[id]": [~28],
		"/privacy-policy": [~29],
		"/prohibited-businesses": [~30],
		"/redirect/recovery": [31],
		"/ref/[ref_id]": [~32],
		"/request-access": [~33],
		"/restricted-jurisdictions": [~34],
		"/sign-in": [~35],
		"/sign-up": [~36],
		"/terms-and-conditions": [~37],
		"/[affiliate]": [~7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';